import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

// 参照元
// https://github.com/dwyl/learn-to-send-email-via-google-script-html-no-server

const Wrapper = styled.div`
  body {
    margin: 1em;
  }
  aside {
    padding: 0.3em;
    border-radius: 3px;
    color: #000;
    margin-bottom: 2em;
  }

  .content-head {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 1em 0 0;
  }
  .is-center {
    text-align: center;
  }

  .pure-button {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin: 1em auto;
    text-align: center;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
      box-shadow: 0 2px 4px #6d6d6d;
      transition: 0.6s;
    }
  }

  .button-success {
    padding: 5px 20px;
    border-radius: 4px;
    background: #424242;
  }

  .button-xlarge {
    font-size: 125%;
  }

  #name,
  #email {
    width: 50%;
  }
  .honeypot-field {
    display: none;
  }

  fieldset {
    margin-top: 1.5em;
    margin-left: 2em;
  }

  input {
    font-size: 14px;
    width: 200px;
    background: #fff;
    border: 1px lightgray solid;
    border-radius: 3px;
    padding: 0.3em;
  }

  textarea {
    font-size: 14px;
    width: 70%;
    border: 1px lightgray solid;
    border-radius: 3px;
    padding: 0.3em;
  }
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;

  color: #000;

  text-align: center;

  span {
    display: inline-block;
  }
`

const StyledLink = styled(Link)`
  margin: 1em auto;
  display: inline-block;
  padding: 0.3em 1em;
  background: #2962ff;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  &:hover {
    background: ${props => props.theme.colors.highlight};
  }
`

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet>
          {/* <script
            data-cfasync="false"
            src="form-submission-handler.js"
            type="text/babel"
          /> */}
        </Helmet>
        <Wrapper>
          <SEO title="お問い合わせフォーム" />
          <Title>
            <span>お問い合わせ</span>
            <span>フォーム</span>
          </Title>

          <h2 className="content-head is-center">お問い合わせはこちらから。</h2>
          <aside className="is-center">
            <p>
              サイトの不具合、受験に関するご相談、教育実習の悩みなど、何でもお気軽にご連絡ください😉
            </p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdUzzOqdPv0t_tbprfe2qUFY-ip6ArN07Hcv_3JF4uMkjqLbw/viewform?usp=sf_link" >お問い合わせ</a>
          </aside>

          <div className="is-center">
            <StyledLink to={`/`} className="cat-item__link">
              トップへ戻る
            </StyledLink>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
